import React from "react";
import { Link } from "gatsby";
import logo from "../images/logo.png";
import {
  FaPhoneAlt,
  FaHome,
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
  FaEnvelope,
} from "react-icons/fa";

class Navigation extends React.Component {
  state = {
    showMenu: false,
    scrollClass: "parked",
  };

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };

  rollOff = () => {
    this.setState({ showMenu: "" });
  };

  //Add class when scrolling
  componentDidMount() {
    window.addEventListener("scroll", () => {
      let scrollClass = "scrolling";
      if (window.scrollY <= 80) {
        scrollClass = "parked";
      }
      this.setState({ scrollClass });
    });
  }

  componentWillUnmount() {
    // Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const menuVis = this.state.showMenu ? "active" : "";

    return (
      <header className={`${this.state.scrollClass} ${menuVis}`}>
        <div className="upper-nav">
          <div className="grid-x grid-padding-x">
            <div className="cell">
              <nav>
                <ul>
                  <li className="social">
                    <a
                      href="https://www.instagram.com/clarehouseok/"
                      aria-label="Instagram"
                    >
                      <FaInstagramSquare />
                    </a>
                  </li>
                  <li className="social">
                    <a
                      href="https://www.youtube.com/user/ClarehouseInc"
                      aria-label="YouTube"
                    >
                      <FaYoutubeSquare />
                    </a>
                  </li>
                  <li className="social">
                    <a
                      href="https://www.facebook.com/ClarehouseTulsa"
                      aria-label="Facebook"
                    >
                      <FaFacebookSquare />
                    </a>
                  </li>
                  {/* <li>
                    <Link to="https://interland3.donorperfect.net/weblink/WebLink.aspx?name=E348112&id=59">
                      <FaHeart /> Memorial Service Registration
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/joinourteam/">
                      <FaHome /> Work Here
                    </Link>
                  </li>
                  <li>
                    <Link to="#contact">
                      <FaEnvelope /> Contact Us
                    </Link>
                  </li>
                  <li className="callus">
                    <a
                      href="tel:9188936150"
                      aria-label="Phone"
                    >
                      <FaPhoneAlt /> 918-893-6150
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className={`lower-nav ${menuVis}`}>
          <div className="grid-x grid-padding-x">
            <div className="cell">
              <nav>
                <Link to="/">
                  <img
                    className="logo"
                    src={logo}
                    alt="Clarehouse Logo"
                  />
                </Link>
                <button
                  className="nav-icon"
                  onKeyDown={this.toggleMenu}
                  onClick={this.toggleMenu}
                  aria-label="Toggle navigation menu"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <ul className="menu">
                  <li className="mobile-only">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="has-dropdown">
                    <Link
                      className="base-link"
                      to="/about-us"
                    >
                      About
                    </Link>
                    <ul>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="/care-at-clarehouse">24/7 Care</Link>
                      </li>
                      <li>
                        <Link to="/gallery">Gallery</Link>
                      </li>
                      <li>
                        <Link to="/board-and-staff">Board &amp; Staff</Link>
                      </li>
                      <li>
                        <Link to="/adopt-our-model">Adopt Our Model</Link>
                      </li>
                      <li className="mobile-only">
                        <Link to="/joinourteam/">Work Here</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/events">Events</Link>
                  </li>
                  <li>
                    <Link to="/volunteers">Volunteer</Link>
                  </li>
                  <li className="has-dropdown">
                    <Link
                      className="base-link"
                      to="/education"
                    >
                      Education
                    </Link>
                    <ul>
                      <li>
                        <Link to="/education">Education &amp; Outreach</Link>
                      </li>
                      <li>
                        <Link to="/clinical-education">Clinical Education</Link>
                      </li>
                      <li>
                        <Link to="/resources">Resources</Link>
                      </li>
                      <li>
                        <Link to="/programs">Programs</Link>
                      </li>
                      <li>
                        <Link to="/the-sue-holloway-memorial-scholarships">
                          Scholarships
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-dropdown">
                    <Link
                      className="base-link"
                      to="/donate"
                    >
                      Support Us
                    </Link>
                    <ul>
                      <li>
                        <Link to="/donate">Ways To Donate</Link>
                      </li>
                      <li>
                        <Link to="/recent-donors">Recent Donors</Link>
                      </li>
                      <li>
                        <Link to="/the-circle">The Circle</Link>
                      </li>
                      <li>
                        <Link to="/legacy-society/">Legacy Society</Link>
                      </li>
                      <li>
                        <Link to="/wish-list">Our Wish List</Link>
                      </li>
                      <li>
                        <Link to="/events/moonstruck">Annual Fundraiser</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="cta">
                    <a
                      className="button"
                      href="https://interland3.donorperfect.net/weblink/weblink.aspx?name=E348112&id=2"
                    >
                      Donate
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Navigation;
